/*!
 * CSS PROJECT files
 * Author : Darius
 */

// Core
@import "assets/scss/project/vendors/material";
@import "assets/scss/project/vendors/bootstrap";
@import "~ng-pick-datetime/assets/style/picker.min.css";

// Processed files (DON'T EDIT)
@import "assets/scss/processed/index";

// Project files (YOU CAN EDIT THOSE FILES)
@import "assets/scss/project/hermes";


.input-filter {
  width: 259px;
  height: 60px;
}

.body {
  max-width: 110em;
  margin-left: auto;
  margin-right: auto;
}

.mat-select-trigger {
  height: 100%;
}

.mat-select-value {
  padding: 10px;
}

.ngx-slider-pointer:after {
  width: 14px !important;
  height: 14px !important;
  top: 9px !important;
  border-radius: 8px !important;
  background: #13b9f0 !important;
}

.ngx-slider-pointer {
  background-color: transparent !important;
}

.ngx-slider-bubble {
  color: #00aec7 !important;
}

.ngx-slider-limit {
  color: black !important;
}

.mat-radio-group {
  margin-top: 15px;
}

.ngx-timepicker {
  border: 1px solid #969696 !important;
  border-radius: 5px;
  width: 190px;
  height: 40px !important;
}

.ngx-timepicker__toggle {
  margin-left: 15px;
}

.ngx-timepicker-control__arrows {
  top: 3px  !important;
}

.ngx-timepicker-control__arrow {
  font-size: 9px  !important;
  color:black !important;
}

.ngx-timepicker-control__input {
  font-size: 16px  !important;
  font-weight: bold;
  color:black;
}

.mat-form-field-flex {
  background-color: transparent !important;
  border: 1px solid #969696 !important;
  border-radius: 5px;
  height: 40px;
  padding: 0px !important;
  font-size: 16px;
}

.mat-form-field-infix {
  bottom: 6px;
  left: 5px;
  font-size: 18px;
}

.timepicker-button {
  color: #0D47A1 !important;
}

.mat-form-field-underline {
  display: none;
}

.mat-select-arrow-wrapper {
  padding-top: 10px;
  padding-right: 5px;
}

.bdrop {
  background-color: #bbbbbbf2;
  backdrop-filter: blur(4px);
}


.mat-form-field-wrapper {
  padding-bottom: 0px;
}

.mat-menu-panel, .mat-menu-content {
  overflow-y: hidden;
  overflow-x: hidden;
}

.mat-option-text {
  font-size: 18px;
}


.cdk-overlay-pane {
  min-width: 180px !important;
  margin-left: 35px;
}
.mat-date-range-input {
  font-size: .9em;
}