@import "@angular/material/theming";
@import "../../processed/utilities/functions";
@import "../../processed/utilities/mixins";
@import "../../processed/utilities/variables";

@include mat-core();

$veolia-primary: mat-palette($mat-blue, 300);
$veolia-accent: mat-palette($mat-blue, 900);
$veolia-warn: mat-palette($mat-red);

$veolia-theme: mat-light-theme($veolia-primary, $veolia-accent, $veolia-warn);

@include angular-material-theme($veolia-theme);

.mat-horizontal-stepper-header-container {
  background-color: veolia-color("white");
}

.mat-stepper-horizontal {
  background-color: inherit;
}

.mat-horizontal-content-container {
  padding: 0 !important;
}

.mat-form-field-label {
  font-size: 14px !important;
}

.mat-toolbar {
  width: 80% !important;
  margin: auto;
  color: veolia-color("white");
  background-color: veolia-color("secondary") !important;
}

.mat-button-toggle-group {
  height: 40px !important;
}

.mat-button-toggle-button {
  height: 38px !important;
  vertical-align: middle;
}

.mat-button-toggle-label-content {
  line-height: 38px !important;
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: veolia-color("white") !important;
  background-color: veolia-color("primary") !important;
}

.mat-button-toggle-appearance-standard {
  color: veolia-color("primary") !important;
  background-color: veolia-color("white") !important;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.mat-dialog-container {
  padding: 0px !important;
}

.mat-dialog-content {
  padding-top: 12px !important;
  margin: 0px !important;
}

.mat-dialog-actions {
  padding: 8px 24px;
}

.mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

mat-slide-toggle {
  margin: 8px;
}
